import EmberRouter from '@ember/routing/router';
import config from './config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('login');

  this.route('stats', function () {
    this.route('dashboard');
    this.route('reports', function () {
      this.route('by-deposit-withdraw');
      this.route('by-bets');
      this.route('automatic-sport-kpi');
    });
  });

  this.route('risk-management', function () {
    this.route('bets');
    this.route('players', function () {
      this.route('profile', { path: '/:player_id' }, function () {
        this.route('stats-profile');
        this.route('actions');
        this.route('bet-history');
        this.route('documents');
        this.route('edit-logs');
        this.route('messages');
        this.route('password');
        this.route('transaction');
        this.route('casino-bet-history');
        this.route('bonuses');
        this.route('notes');
        this.route('transaction-history-log');
      });
    });
    this.route('limits', function () {
      this.route('global');
      this.route('sports-leagues');
      this.route('markets');
    });
    this.route('transactions', function () {
      this.route('retail', function () {});
      this.route('online', function () {});
    });
    this.route('withdraw-requests', function () {});
    this.route('odds-feed', function () {
      this.route('markets');
      this.route('events');
      this.route('sports');
      this.route('leagues');
    });

    this.route('bet-history', function () {
      this.route('sport');
      this.route('casino');
    });
    this.route('manual-withdraw-tricks');
  });

  this.route('casino-players', function () {
    this.route('bulk-credit')
  })

  this.route('route-components', function () {
    this.route('risk-management', function () {
      this.route('players', function () {});
    });
  });

  this.route('sportsbook', function () {
    this.route('dashboard');
    this.route('settings', function () {
      this.route('template', { path: '/:template_id' }, function () {
        this.route('sports');
        this.route('leagues');
        this.route('order');
        this.route('markets');
        this.route('games');
      });
    });
    this.route('banners', function () {
      this.route('banner', { path: '/usage-type/:usage_type_id' });
    });
    this.route('right-section-banners');
  });

  this.route('bonuses', function () {
    this.route('promotion-pages');
    this.route('bonus-templates', function () {
      this.route('site');
      this.route('affiliate');
      this.route('create');
      this.route('edit', { path: '/:bonus_id' });
    });
    this.route('dashboard');
    this.route('wheel-templates');
    this.route('wheel-logic');
    this.route('wheel-shop');
    this.route('wheel-page-info');
    this.route('promo');
  });

  this.route('casino', function () {
    this.route('dashboard');
    this.route('settings', function () {
      this.route('template', { path: '/:template_id' }, function () {
        this.route('order');
        this.route('categories');
        this.route('sub-categories');
        this.route('types');
        this.route('providers');
        this.route('games');
        this.route('ranking');
        this.route('wagering-contribution');
      });
    });
    this.route('banners');
    this.route('integration', function () {
      this.route('setting', { path: '/:integration_id' });
    });
    this.route('free-spins-usage');
  });

  this.route('retail', function () {
    this.route('administrators', function () {
      this.route('administrator', { path: '/:administrator_id' }, function () {
        this.route('details');
        this.route('managers');
        this.route('cashiers');
        this.route('transaction-history');
      });
    });

    this.route('managers', function () {
      this.route('manager', { path: '/:manager_id' }, function () {
        this.route('details');
        this.route('cashiers');
        this.route('transaction-history');
      });
    });

    this.route('cashiers', function () {
      this.route('cashier', { path: '/:cashier_id' }, function () {
        this.route('details');
        this.route('transaction-history');
      });
    });

    this.route('wallets', function () {
      this.route('transactions', { path: '/:currency_id' });
    });
    this.route('cashboxes', function () {
      this.route('details', { path: '/:cashbox_id' });
    });
    this.route('transactions');
    this.route('reports', function () {
      this.route('remaining-credits');
      this.route('sold-credits');
    });
  });

  this.route('affiliates', function () {
    this.route('dashboard');
    this.route('links-banners', function () {
      this.route('edit', { path: '/:affiliate_link_id' });
      this.route('create', { path: 'create/:user_id' });
    });
    this.route('reports');
    this.route('transactions', function () {
      this.route('history');
      this.route('withdraw_request');
    });
    this.route('sub-affiliates');
    this.route('settings');
    this.route('clients', function () {
      this.route('profile', { path: '/:player_id' }, function () {
        this.route('stats-profile');
        this.route('bet-history');
        this.route('transaction');
      });
    });
    this.route('wallets', function () {
      this.route('withdraw', { path: '/:account_id' }, function () {});
    });
  });

  this.route('settings', function () {
    this.route('users-roles', function () {
      this.route('roles');
      this.route('users');
    });
    this.route('navigations', function () {
      this.route('header');
      this.route('footer');
    });
    this.route('slack-messages');
    this.route('admin-panel');
    this.route('translations');
    this.route('partners', function () {
      this.route('domain-manager', { path: '/:partner_id/domain-manager' }, function () {
        this.route('main-config');
        this.route('mirrors');
        this.route('backup-domains');
      });
      this.route('details', { path: '/:partner_id/details' });
      this.route('configs', { path: '/:partner_id/configs' });
      this.route('groups');
    });

    this.route('code-injection', function () {
      this.route('desktop');
      this.route('mobile');
    });
    this.route('seo');
    this.route('marketing', function () {
      this.route('mail-templates');
      this.route('smtp-mail-templates');
      this.route('sms');
      this.route('site-messages');
    });
    this.route('segment', function () {});

    this.route('bonus-templates', function () {});

    this.route('max-bet', function () {
      this.route('setting', { path: '/:currency_group_id' });
    });
    this.route('deposit-buttons');
  });

  this.route('payment', function () {
    this.route('dashboard');
    this.route('integration', function () {
      this.route('settings', { path: '/:integration_id' }, function () {
        this.route('deposit-method', { path: '/:deposit_method_integration_setting_id/deposit-method' });
        this.route('withdraw-method', { path: '/:withdraw_method_integration_setting_id/withdraw-method' });
      });
    });
    this.route('setting', function () {
      this.route('template', { path: '/:template_id' }, function () {
        this.route('order');
        this.route('category');
        this.route('deposit-method');
        this.route('withdraw-method');
      });
    });

    this.route('payment-methods', function () {
      this.route('payment-method-detail', { path: '/:provider/:method/:merchant_id' }, function () {
        this.route('configuration');
      });
    });

    this.route('banners');
    this.route('currency-courses');
  });

  this.route('affiliate', function () {
    this.route('transactions');
  });

  this.route('controllers', function () {
    this.route('stats', function () {
      this.route('reports', function () {});
    });
  });

  this.route('cms', function () {
    this.route('logo-styles');
    this.route('banners', function () {
      this.route('usage_type', { path: '/:usage_type' });
    });
    this.route('navigations', function () {
      this.route('header');
      this.route('footer');
      this.route('mobile-footer');
    });
    this.route('pages', function () {
      this.route('static');
      this.route('manual');
    });
  });

  this.route('tournaments', function () {
    this.route('tournaments', function () {
      this.route('create');
      this.route('edit', { path: '/:tournament_id' });
    });
  });
});
