import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action, computed } from '@ember/object';
import { A } from '@ember/array';

export default class NavigationService extends Service {
  @service currentUser;
  @service router;
  @service store;
  @service intl;

  @tracked siteContent;
  @tracked selected;
  @tracked customHeaderTitle = null;
  @tracked playerVerified = false;
  @tracked isShowIcon = true;
  @tracked selectedSubMenu;
  @tracked selected_administrator_id;
  @tracked selected_manager_id;
  @tracked selected_cashier_id;

  @tracked urls = A([]);

  settings_users_roles = A([
    {
      linkTo: 'settings.users-roles.users',
      definer: 'settings_users_roles_key_users',
    },
    {
      linkTo: 'settings.users-roles.roles',
      definer: 'settings_users_roles_key_roles',
    },
  ]);

  bonuses_bonus_templates = A([
    {
      linkTo: 'bonuses.bonus-templates.site',
      definer: 'bonuses_bonus_templates',
    },
    {
      linkTo: 'bonuses.bonus-templates.affiliate',
      definer: 'bonuses_bonus_templates',
    },
  ]);

  settings_code_injection = A([
    {
      linkTo: 'settings.code-injection.desktop',
      definer: 'settings_code_injection',
    },
    {
      linkTo: 'settings.code-injection.mobile',
      definer: 'settings_code_injection',
    },
  ]);

  risk_management_limits = A([
    {
      linkTo: 'risk-management.limits.global',
      definer: 'risk_management_limits',
    },
    {
      linkTo: 'risk-management.limits.sports-leagues',
      definer: 'risk_management_limits',
    },
    {
      linkTo: 'risk-management.limits.markets',
      definer: 'risk_management_limits',
    },
  ]);

  risk_management_bet_history = A([
    {
      linkTo: 'risk-management.bet-history.casino',
      definer: 'r_m_bet_history_casino',
    },
  ]);

  risk_management_transactions = A([
    {
      linkTo: 'risk-management.transactions.online',
      definer: 'r_m_transactions_key_online',
    },
  ]);

  stats_reports = A([
    {
      linkTo: 'stats.reports.by-bets',
      definer: 'stats_reports_key_by_partner',
    },
    {
      linkTo: 'stats.reports.by-deposit-withdraw',
      definer: 'stats_reports_key_retail_transactions',
    },
    {
      linkTo: 'stats.reports.automatic-sport-kpi-report',
      definer: 'stats_reports_key_automatic_sport_kpi',
    },
  ]);

  affiliates_transactions = A([
    {
      linkTo: 'affiliates.transactions.history',
      definer: 'affiliates_transactions_history',
    },
    {
      linkTo: 'affiliates.transactions.withdraw_request',
      definer: 'affiliates_transactions_withdraw_request',
    },
  ]);

  retail_reports = A([
    {
      linkTo: 'retail.reports.remaining-credits',
      definer: 'retail_reports_key_remaining_credits',
    },
    {
      linkTo: 'retail.reports.sold-credits',
      definer: 'retail_reports_key_sold_credits',
    },
  ]);

  payment_settings_details = A([
    {
      linkTo: 'payment.settings.cashbox.purse',
      definer: 'payment',
    },
  ]);

  payment_methods = A([
    {
      linkTo: 'payment.payment-methods.payment-method-detail.configuration',
      definer: 'payment_methods',
    },
  ]);

  risk_management_players_details = A([
    {
      linkTo: 'risk-management.players.profile.stats-profile',
      definer: 'r_m_players_key_stats_and_profile',
    },
    {
      linkTo: 'risk-management.players.profile.wallets',
      definer: 'r_m_players_key_wallets',
    },
    {
      linkTo: 'risk-management.players.profile.actions',
      definer: 'r_m_players_key_actions',
    },
    {
      linkTo: 'risk-management.players.profile.bet-history',
      definer: 'r_m_players_key_bet_history',
    },
    {
      linkTo: 'risk-management.players.profile.casino-bet-history',
      definer: 'r_m_players_key_bet_history',
    },
    {
      linkTo: 'risk-management.players.profile.documents',
      definer: 'r_m_players_key_documents',
    },
    {
      linkTo: 'risk-management.players.profile.messages',
      definer: 'r_m_players_key_messages',
    },
    {
      linkTo: 'risk-management.players.profile.bonuses',
      definer: 'r_m_players_key_bonuses',
    },
    {
      linkTo: 'risk-management.players.profile.transaction',
      definer: 'r_m_players_key_transaction',
    },
    {
      linkTo: 'risk-management.players.profile.password',
      definer: 'r_m_players_key_password',
    },
    {
      linkTo: 'risk-management.players.profile.edit-logs',
      definer: 'r_m_players_key_edit_logs',
    },
    {
      linkTo: 'risk-management.players.profile.notes',
      definer: 'r_m_players_key_player_notes',
    },
    {
      linkTo: 'risk-management.players.profile.transaction-history-log',
      definer: 'r_m_players_key_transaction_history_log',
    },
  ]);

  retail_administrators_details = A([
    {
      linkTo: 'retail.administrators.administrator.details',
      definer: 'retail_administrators_key_details',
    },
    {
      linkTo: 'retail.administrators.administrator.managers',
      definer: 'retail_administrators_key_managers',
    },
    {
      linkTo: 'retail.administrators.administrator.cashiers',
      definer: 'retail_administrators_key_cashiers',
    },
    {
      linkTo: 'retail.administrators.administrator.deposit',
      definer: 'retail_administrators_key_deposit',
    },
    {
      linkTo: 'retail.administrators.administrator.transaction-history',
      definer: 'retail_administrators_key_transaction_history',
    },
  ]);

  retail_managers_details = A([
    {
      linkTo: 'retail.managers.manager.details',
      definer: 'retail_managers_key_details',
    },
    {
      linkTo: 'retail.managers.manager.cashiers',
      definer: 'retail_managers_key_cashiers',
    },
    {
      linkTo: 'retail.managers.manager.deposit',
      definer: 'retail_managers_key_deposit',
    },
    {
      linkTo: 'retail.managers.manager.transaction-history',
      definer: 'retail_managers_key_transaction_history',
    },
  ]);

  retail_cashiers_details = A([
    {
      linkTo: 'retail.cashiers.cashier.details',
      definer: 'retail_cashiers_key_details',
    },
    {
      linkTo: 'retail.cashiers.cashier.deposit',
      definer: 'retail_cashiers_key_deposit',
    },
    {
      linkTo: 'retail.cashiers.cashier.transaction-history',
      definer: 'retail_cashiers_key_transaction_history',
    },
  ]);

  affiliates_clients_details = A([
    {
      linkTo: 'affiliates.clients.profile.stats-profile',
      definer: 'affiliates_clients_stats_profile',
    },
    {
      linkTo: 'affiliates.clients.profile.bet-history',
      definer: 'affiliates_clients_bet_history',
    },
    {
      linkTo: 'affiliates.clients.profile.transaction',
      definer: 'affiliates_clients_transaction',
    },
  ]);

  mapping = A([
    {
      linkTo: 'stats.dashboard',
      definer: 'stats_dashboard',
    },
    {
      linkTo: 'payment.dashboard',
      definer: 'payment_dashboard',
    },
    {
      linkTo: 'payment.integration',
      definer: 'payment_integration',
    },
    {
      linkTo: 'payment.setting',
      definer: 'payment_setting',
    },
    {
      linkTo: 'payment.banners',
      definer: 'payment_banners',
    },
    {
      linkTo: 'payment.currency-courses',
      definer: 'currency_courses',
    },
    {
      linkTo: 'payment.payment-methods',
      definer: 'payment_methods',
    },
    {
      linkTo: 'stats.reports',
      definer: 'stats_reports',
      haveManyLink: true,
    },
    {
      linkTo: 'sportsbook.dashboard',
      definer: 'sportsbook_dashboard',
    },
    {
      linkTo: 'sportsbook.settings',
      definer: 'sportsbook_settings',
    },
    {
      linkTo: 'sportsbook.right-section-banners',
      definer: 'right_section_banners',
    },
    {
      linkTo: 'sportsbook.banners',
      definer: 'sportsbook_banners',
    },
    {
      linkTo: 'sportsbook.right-section-banners',
      definer: 'right_section_banners',
    },
    {
      linkTo: 'retail.reports',
      definer: 'retail_reports',
      haveManyLink: true,
    },
    {
      linkTo: 'retail.administrators',
      definer: 'retail_administrators',
    },
    {
      linkTo: 'retail.managers',
      definer: 'retail_managers',
    },
    {
      linkTo: 'retail.cashiers',
      definer: 'retail_cashiers',
    },
    {
      linkTo: 'retail.cashboxes',
      definer: 'retail_cashboxes',
    },
    {
      linkTo: 'retail.wallets',
      definer: 'retail_wallets',
    },
    {
      linkTo: 'retail.cashboxes',
      definer: 'retail_cashboxes',
    },
    {
      linkTo: 'retail.transactions',
      definer: 'retail_transactions',
    },
    {
      linkTo: 'casino.dashboard',
      definer: 'casino_dashboard',
    },
    {
      linkTo: 'casino.settings',
      definer: 'casino_settings',
    },
    {
      linkTo: 'casino.banners',
      definer: 'casino_banners',
    },
    {
      linkTo: 'casino.integration',
      definer: 'casino_integration',
    },
    {
      linkTo: 'casino.free-spins-usage',
      definer: 'casino_free_spin',
    },
    {
      linkTo: 'casino-players.bulk-credit',
      definer: 'casino_players'
    },
    {
      linkTo: 'casino-players.bulk-credit',
      definer: 'casino_players_bulk_credit'
    },
    {
      linkTo: 'risk-management.bets',
      definer: 'risk_management_bets',
    },
    {
      linkTo: 'risk-management.players',
      definer: 'risk_management_players',
    },
    {
      linkTo: 'risk-management.manual-withdraw-tricks',
      definer: 'risk_management_manual_withdraw_tricks',
    },
    {
      linkTo: 'risk-management.limits',
      definer: 'risk_management_limits',
      haveManyLink: true,
    },
    {
      linkTo: 'risk-management.bet_history',
      definer: 'risk_management_bet_history',
      haveManyLink: true,
    },
    {
      linkTo: 'risk-management.transactions',
      definer: 'risk_management_transactions',
      haveManyLink: true,
    },
    {
      linkTo: 'risk-management.withdraw-requests',
      definer: 'risk_management_withdraw_requests',
    },
    {
      linkTo: 'risk-management.odds-feed',
      definer: 'risk_management_odds_feed',
    },
    {
      linkTo: 'affiliates.dashboard',
      definer: 'affiliates_dashboard',
    },
    {
      linkTo: 'affiliates.links-banners',
      definer: 'affiliates_links_banners',
    },
    {
      linkTo: 'affiliates.wallets',
      definer: 'affiliates_wallets',
    },
    {
      linkTo: 'bonuses.dashboard',
      definer: 'bonuses_dashboard',
    },
    {
      linkTo: 'affiliates.reports',
      definer: 'affiliates_reports',
    },
    {
      linkTo: 'affiliates.clients',
      definer: 'affiliates_clients',
    },
    {
      linkTo: 'affiliates.settings',
      definer: 'affiliates_settings',
    },
    {
      linkTo: 'affiliates.sub-affiliates',
      definer: 'affiliates_sub_affiliates',
    },
    {
      linkTo: 'affiliates.transactions',
      definer: 'affiliates_transactions',
      haveManyLink: true,
    },
    {
      linkTo: 'bonuses.promotion-pages',
      definer: 'bonuses_promotion_pages',
    },
    {
      linkTo: 'bonuses.bonus-templates',
      definer: 'bonuses_bonus_templates',
      haveManyLink: true,
    },
    {
      linkTo: 'bonuses.wheel-templates',
      definer: 'bonuses_wheel_template',
    },
    {
      linkTo: 'bonuses.wheel-logic',
      definer: 'bonuses_wheel_logic',
    },
    {
      linkTo: 'bonuses.wheel-shop',
      definer: 'bonuses_wheel_shop',
    },
    {
      linkTo: 'bonuses.promo',
      definer: 'bonuses_promo',
    },
    {
      linkTo: 'bonuses.wheel-page-info',
      definer: 'bonuses_wheel_page_info',
    },
    {
      linkTo: 'settings.users-roles',
      definer: 'settings_users_roles',
      haveManyLink: true,
    },
    {
      linkTo: 'settings.deposit-buttons',
      definer: 'settings_deposit_buttons',
    },
    {
      linkTo: 'settings.deposit-buttons',
      definer: 'settings_deposit_buttons',
    },
    {
      linkTo: 'cms.pages',
      definer: 'cms_pages',
    },
    {
      linkTo: 'cms.navigations',
      definer: 'cms_navigation',
    },
    {
      linkTo: 'settings.slack-messages',
      definer: 'settings_slack_messages',
    },
    {
      linkTo: 'cms.logo-styles',
      definer: 'cms_logo_styles',
    },
    {
      linkTo: 'settings.admin-panel',
      definer: 'settings_admin_panel',
    },
    {
      linkTo: 'settings.translations',
      definer: 'settings_translations',
    },
    {
      linkTo: 'settings.partners',
      definer: 'settings_partners',
    },
    {
      linkTo: 'settings.code-injection',
      definer: 'settings_code_injection',
      haveManyLink: true,
    },
    {
      linkTo: 'settings.marketing',
      definer: 'settings_marketing',
    },
    {
      linkTo: 'settings.seo',
      definer: 'settings_seo',
    },
    {
      linkTo: 'cms.banners',
      definer: 'cms_banners',
    },
    {
      linkTo: 'settings.segment',
      definer: 'settings_segment',
    },
    {
      linkTo: 'settings.max-bet',
      definer: 'settings_max_bet_configuration'
    },
    {
      linkTo: 'settings.deposit-buttons',
      definer: 'settings_deposit_buttons',
    },
    {
      linkTo: 'tournaments.tournaments',
      definer: 'tournaments_tournaments',
    },
  ]);

  excludeItems = ['payment'];

  @computed('router.currentRouteName', 'settings_users_roles')
  get urlWithSystemPartners() {
    let currentUrl = this.router.currentRouteName;
    return !!this.settings_users_roles.map((data) => data.linkTo).includes(currentUrl);
  }

  constructor() {
    super(...arguments);
    this.siteContent = this.store.peekAll('site-content').filter((content) => !this.excludeItems.includes(content.definer));
    let content = this.siteContent.find((i) => i.type === 'menu');
    if (content) {
      this.selected = content.id;
    }
  }

  groupByParentTitle(items) {
    let result = [];
    items.forEach((item) => {
      let title = this.store.peekRecord('site-content', item['parent_id']).title;
      let current = result.find((r) => {
        return r.title === title;
      });

      if (!current) {
        current = {
          title,
          items: [],
        };

        result.push(current);
      }

      current.items.push(item);
    });

    return result;
  }

  excludeSubMenus = ['risk_management_bets', 'risk_management_limits', 'risk_management_odds_feed', 'risk_management_manual_withdraw_tricks'];

  get subMenus() {
    let favorites = this.store.peekAll('site-content-favorite');

    if (this.isFavorite) {
      let items = this.siteContent.filter((val) => {
        return favorites.find((i) => i.site_content_id === parseInt(val.id));
      });

      return this.groupByParentTitle(items);
    }

    return this.siteContent.filter((val) => {
      val.is_favorite = !!favorites.find((i) => i.site_content_id === parseInt(val.id));
      return parseInt(val.parent_id) === parseInt(this.selected) && !this.excludeSubMenus.includes(val.definer);
    });
  }

  @computed('router.{currentRoute,currentRouteName}', 'urls.length')
  get links() {
    this.urls.clear();
    this.getParentLink(this.router.currentRoute);
    let title;
    if(this.router.currentRouteName === 'casino.integration.index') {
      title = this.intl.t('Providers');
      this.urls.clear();
      this.urls.push({ title: title, params: [] });
      return this.urls;
    }

    title = this.getSubMenuTitle(this.router.currentRouteName);
    if (title) this.urls.push({ title: title, params: [] });
    if (this.urls.length > 3 && this.router.currentRouteName.search('partners') < 0) {
      delete this.urls[2].linkTo;
      this.urls[1].className = 'text-underline text-uppercase';
    }
    if (this.urls.length >= 3 && this.router.currentRouteName.search('partners') >= 0) {
      this.urls[1].className = 'text-underline text-uppercase';
    }
    this.urls.shift();
    return this.urls;
  }

  getParentLink(router) {
    if (router.parent && router.parent.name !== 'application') {
      let linkTo = router.parent;
      let title = this.getSubMenuTitle(linkTo.name);
      let params = A([]);
      Object.keys(linkTo.params).forEach((key) => {
        params.push(linkTo.params[key]);
      });
      if (this.router.currentRouteName.split('.').length === 3 && this.router.currentRouteName.search('partners') < 0)
        linkTo = this.router.currentRouteName;
      this.urls.unshift({ linkTo: linkTo.name, title: title, params: params });
      this.getParentLink(router.parent);
    }
  }

  getSubMenuTitle(argument) {
    let title = '';
    let index = argument.lastIndexOf('.');
    let key = argument.substring(index + 1);
    if (key !== 'index') {
      let titleLine = key.split('-');
      if (titleLine.length === 2) {
        title += titleLine[0].charAt(0).toUpperCase() + titleLine[0].slice(1) + ' ' + titleLine[1].charAt(0).toUpperCase() + titleLine[1].slice(1);
      } else {
        title += key.charAt(0).toUpperCase() + key.slice(1);
      }
      return title;
    }
  }

  @computed('router.currentRouteName', 'customHeaderTitle')
  get headerTitle() {
    let routeName = this.router.currentRouteName;
    if(routeName === 'casino.integration.index') {
      return this.intl.t('Providers');
    }
    if ((routeName.match(/\./g) || []).length >= 1 && !this.customHeaderTitle) {
      if (routeName.indexOf('.index') > 0) {
        routeName = routeName.replace('.index', '');
      }
      let title = routeName.split('.')[1];
      let titleLine = title.split('-');
      if (titleLine.length === 2) {
        return titleLine[0].charAt(0).toUpperCase() + titleLine[0].slice(1) + ' ' + titleLine[1].charAt(0).toUpperCase() + titleLine[1].slice(1);
      }
      if (titleLine.length === 3) {
        return (
          titleLine[0].charAt(0).toUpperCase() +
          titleLine[0].slice(1) +
          ' ' +
          titleLine[1].charAt(0).toUpperCase() +
          titleLine[1].slice(1) +
          ' ' +
          titleLine[2].charAt(0).toUpperCase() +
          titleLine[2].slice(1)
        );
      }
      return title.charAt(0).toUpperCase() + routeName.split('.')[1].slice(1);
    }
    return this.customHeaderTitle;
  }

  @computed('selected')
  get title() {
    return this.store.peekRecord('site-content', this.selected).title;
  }

  @computed('title')
  get isFavorite() {
    return this.title === 'Favorites';
  }

  @action
  setActiveMenu(parent_id) {
    this.selected = parent_id;
  }

  @action
  setActiveSubMenu(id) {
    this.selectedSubMenu = id;
  }
}
